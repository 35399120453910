import { Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { filter, pairwise } from 'rxjs';
import {
  AuthenticationResult,
  PopupRequest,
  RedirectRequest,
  EventMessage,
  EventType,
  InteractionType,
  AccountInfo,
  SsoSilentRequest,
  IdTokenClaims,
  PromptValue,
  InteractionStatus,
} from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { Router, RoutesRecognized, RouterOutlet } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { msalConfig } from './auth/auth-config';
import { RecruitmentSignalRService } from '@v2/core/services/recruitment-signalr.service';
import { ScreeningSignalRService } from '@v2/core/services/screening-signalr.service';
import { PrivacyStatementHttpService } from '@v2/data/service/privacy-statement.http.service';
import { EventService } from '@v2/core/services/event.service';
import { InactivityService } from '@v2/core/services/inactivity.service';
import { isValidAccount } from './auth/authentication.service';
import { LoadingIndicatorComponent } from './layout/loader/loading-indicator.component';
import { LoadingService } from './layout/loader/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [RouterOutlet, LoadingIndicatorComponent],
})
export class AppComponent implements OnInit {
  title = 'Magnit Portal';
  isIframe = false;
  isRouterStateReady = false;

  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private recruitmentSignalRService: RecruitmentSignalRService,
    private screeningSignalRService: ScreeningSignalRService,
    private privacyStatementService: PrivacyStatementHttpService, // <-- without this, translation of `app.componet.ts` is broken ¯\_(ツ)_/¯
    private router: Router,
    private readonly eventService: EventService,
    private inactivityService: InactivityService,
    private readonly loadingService: LoadingService,
  ) {
    this.configureRouterEvents();
    this.watchForIdleActivity();
  }

  checkAndSetActiveAccount(): AccountInfo | null {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */

    const activeAccount = this.msalService.instance.getActiveAccount();
    if (isValidAccount(activeAccount)) {
      return activeAccount;
    }

    const accounts = this.msalService.instance.getAllAccounts();

    for (const acc of accounts) {
      if (isValidAccount(acc)) {
        this.msalService.instance.setActiveAccount(acc);
        return acc;
      }
    }

    return null;
  }

  ngOnInit(): void {
    this.loadingService.loadingOn();

    this.msalService
      .handleRedirectObservable()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((authResult: AuthenticationResult) => {
        if (authResult) {
          const isSupplierAccount = msalConfig.clientId === authResult.account.idTokenClaims?.aud;
          if (isSupplierAccount) {
            this.router.navigate(['/supplier/home']).then(() => {
              this.loadingService.loadingOff();
              this.isRouterStateReady = true;
            });
          }
        } else {
          this.isRouterStateReady = true;
          this.loadingService.loadingOff();
        }
      });

    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

    this.msalService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED,
        ),
      )
      .subscribe(() => {
        if (this.msalService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS,
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        const idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

        if (
          idtoken.acr?.toLowerCase() === msalConfig.policies.signUpSignIn.name?.toLowerCase() ||
          idtoken.tfp?.toLowerCase() === msalConfig.policies.signUpSignIn.name?.toLowerCase()
        ) {
          this.onActiveAccount(payload.account);
        }

        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
         * from SUSI flow. "acr" claim in the id token tells us the policy (NOTE: newer policies may use the "tfp" claim instead).
         * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (
          idtoken.acr?.toLowerCase() === msalConfig.policies.editProfile.name?.toLowerCase() ||
          idtoken.tfp?.toLowerCase() === msalConfig.policies.editProfile.name?.toLowerCase()
        ) {
          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = this.msalService.instance
            .getAllAccounts()
            .find(
              (account: AccountInfo) =>
                account.idTokenClaims?.oid?.toLowerCase() === idtoken.oid?.toLowerCase() &&
                account.idTokenClaims?.sub?.toLowerCase() === idtoken.sub?.toLowerCase() &&
                ((account.idTokenClaims as IdTokenClaimsWithPolicyId).acr?.toLowerCase() ===
                  msalConfig.policies.signUpSignIn.name?.toLowerCase() ||
                  (account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp?.toLowerCase() ===
                    msalConfig.policies.signUpSignIn.name?.toLowerCase()),
            );

          const signUpSignInFlowRequest: SsoSilentRequest = {
            authority: msalConfig.policies.signUpSignIn.authority,
            account: originalSignInAccount,
          };

          // silently login again with the signUpSignIn policy
          this.msalService.ssoSilent(signUpSignInFlowRequest);
        }

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow (see above ln. 74-92).
         */
        if (
          idtoken.acr?.toLowerCase() === msalConfig.policies.resetPassword.name?.toLowerCase() ||
          idtoken.tfp?.toLowerCase() === msalConfig.policies.resetPassword.name?.toLowerCase()
        ) {
          const signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
            authority: msalConfig.policies.signUpSignIn.authority,
            scopes: msalConfig.scopes,
            prompt: PromptValue.LOGIN, // force user to reauthenticate with their new password
          };

          this.login(signUpSignInFlowRequest);
        }

        return result;
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE,
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result: EventMessage) => {
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
          const resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
            authority: msalConfig.policies.resetPassword.authority,
            scopes: [],
          };

          this.login(resetPasswordFlowRequest);
        }
      });

    this.recruitmentSignalRService.init();
    this.screeningSignalRService.init();
  }

  loginRedirect(): void {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  onActiveAccount(account: AccountInfo): void {
    this.msalService.instance.setActiveAccount(account);
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest): void {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.msalService
          .loginPopup({
            ...this.msalGuardConfig.authRequest,
            ...userFlowRequest,
          } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.onActiveAccount(response.account);
          });
      } else {
        this.msalService.loginPopup(userFlowRequest).subscribe((response: AuthenticationResult) => {
          this.onActiveAccount(response.account);
        });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
          ...userFlowRequest,
        } as RedirectRequest);
      } else {
        this.msalService.loginRedirect(userFlowRequest);
      }
    }
  }

  logout(): void {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.msalService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.msalService.logoutRedirect();
    }
  }

  editProfile(): void {
    const editProfileFlowRequest: RedirectRequest | PopupRequest = {
      authority: msalConfig.policies.editProfile.authority,
      scopes: [],
    };

    this.login(editProfileFlowRequest);
  }

  private configureRouterEvents(): void {
    this.router.events
      .pipe(
        filter((event: any) => event instanceof RoutesRecognized),
        pairwise(),
      )
      .subscribe((events: RoutesRecognized[]) => {
        this.eventService.previousNavigatedRoute.next(events[0].urlAfterRedirects);
      });
  }

  private watchForIdleActivity(): void {
    this.inactivityService.onInactive$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.logout();
    });
  }
}

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string;
  tfp?: string;
};
