import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LOCALE_ID, NgModule, ModuleWithProviders } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import localeNl from '@angular/common/locales/nl';
import localeEn from '@angular/common/locales/en';

import { LocaleWrapper } from './localewrapper';

@NgModule({
  imports: [CommonModule, TranslatePipe, TranslatePipe],
  providers: [{ provide: LOCALE_ID, useClass: LocaleWrapper }, provideHttpClient(withInterceptorsFromDi())],
})
export class LanguageModule {
  static forRoot(): ModuleWithProviders<LanguageModule> {
    return {
      ngModule: LanguageModule,
    };
  }
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localeNl);
registerLocaleData(localeEn);
