import {
  Component,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  InjectionToken,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { NgClass, NgTemplateOutlet, NgStyle } from '@angular/common';
import { MatIcon } from '@angular/material/icon';

import { CallPipe } from '../pipes/call.pipe';

const APP_ACCORDION_ITEM_HEADER = new InjectionToken<AccordionItemHeaderDirective>('AppAccordionItemHeaderDirective');

@Directive({
  selector: '[appAccordionItemHeader]',
  providers: [
    {
      provide: APP_ACCORDION_ITEM_HEADER,
      useExisting: AccordionItemHeaderDirective,
    },
  ],
  standalone: true,
})
export class AccordionItemHeaderDirective {
  constructor(public template: TemplateRef<any>) {}
}

const APP_ACCORDION_ITEM_BODY = new InjectionToken<AccordionItemBodyDirective>('AppAccordionItemBodyDirective');

@Directive({
  selector: '[appAccordionItemBody]',
  providers: [
    {
      provide: APP_ACCORDION_ITEM_BODY,
      useExisting: AccordionItemBodyDirective,
    },
  ],
  standalone: true,
})
export class AccordionItemBodyDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Component({
  selector: 'app-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],
  imports: [NgClass, NgTemplateOutlet, MatIcon, NgStyle, CallPipe],
})
export class AccordionItemComponent implements AfterViewInit {
  @ContentChild(APP_ACCORDION_ITEM_HEADER, { read: TemplateRef, static: true })
  _headerTemplate?: TemplateRef<any>;

  @ContentChild(APP_ACCORDION_ITEM_BODY, { read: TemplateRef, static: true })
  _bodyTemplate?: TemplateRef<any>;

  @ViewChild('collapsible') collapsible!: ElementRef<HTMLDivElement>;

  @Input()
  expanded = false;
  @Output() expandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  toggle() {
    this.expanded = !this.expanded;
    this.expandedChange.emit(this.expanded);
  }

  getMarginTop(expanded: boolean, el: HTMLDivElement) {
    return expanded ? '0' : `-${el.offsetHeight}px`;
  }

  ngAfterViewInit() {
    this.collapsible.nativeElement.style.marginTop = this.getMarginTop(this.expanded, this.collapsible.nativeElement);

    setTimeout(() => {
      this.collapsible.nativeElement.style.transition = 'all 0.2s';
    });
  }
}
