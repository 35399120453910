import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { FeatherModule } from 'angular-feather';

@Component({
  selector: 'app-v3-tile-link',
  templateUrl: './tile-link.component.html',
  styleUrls: ['./tile-link.component.scss'],
  imports: [RouterLink, FeatherModule, TranslatePipe],
})
export class TileLinkComponent {
  @Input() url?: string;
  @Input() count?: number;
  @Input() countSuffix = '';
  @Input() icon?: string;
  @Input() title = 'title';
  @Input() class?: string;
}
