import { Component, Input, OnDestroy, OnInit, signal } from '@angular/core';
import { Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import { Subscription } from 'rxjs';
import { MatMenuItem } from '@angular/material/menu';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';

import { AuthenticationService } from '~/app/auth/authentication.service';
import { MemberIdentity } from '@v2/core/security/models/memberidentity';

@Component({
  selector: 'app-user-identity',
  templateUrl: './user-identity.component.html',
  styleUrls: ['./user-identity.component.scss'],
  imports: [MatMenuItem, NgClass, MatIcon],
})
export class UserIdentityComponent implements OnInit, OnDestroy {
  @Input() identity!: MemberIdentity;

  subscriptions: Subscription = new Subscription();
  isActiveMember = signal(false);

  private readonly homePage = '/supplier/home';

  constructor(
    public auth: AuthenticationService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.auth.principal$.subscribe((principal) => {
      const isActiveMember = principal.activeMember.identifier.equals(this.identity.identifier);

      this.isActiveMember.set(isActiveMember);
    });
  }

  switchMember(identifier: Guid): void {
    const subscription = this.auth.principal$.subscribe((principal) => {
      principal.setActiveMember(identifier);
      this.router.navigate([this.homePage]).then(() => {
        window.location.reload();
      });
    });

    this.subscriptions.add(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
