import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, Injector } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import { FeatherModule } from 'angular-feather';

@Component({
  selector: 'app-alert-toast-component',
  styleUrls: ['alert.component.scss'],
  templateUrl: 'alert.toast.html',
  animations: [
    trigger('flyInOut', [
      state(
        'inactive',
        style({
          opacity: 0,
        }),
      ),
      transition(
        'inactive => active',
        animate(
          '400ms ease-out',
          keyframes([
            style({
              transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
              opacity: 0,
            }),
            style({
              transform: 'skewX(20deg)',
              opacity: 1,
            }),
            style({
              transform: 'skewX(-5deg)',
              opacity: 1,
            }),
            style({
              transform: 'none',
              opacity: 1,
            }),
          ]),
        ),
      ),
      transition(
        'active => removed',
        animate(
          '400ms ease-out',
          keyframes([
            style({
              opacity: 1,
            }),
            style({
              transform: 'translate3d(100%, 0, 0) skewX(30deg)',
              opacity: 0,
            }),
          ]),
        ),
      ),
    ]),
  ],
  preserveWhitespaces: false,
  imports: [FeatherModule],
})
export class AlertToastComponent extends Toast {
  iconName: string | null = null;
  private typesStyles = new Map<string, string>([
    ['toast-success', 'check-circle'],
    ['toast-warning', 'alert-triangle'],
    ['toast-error', 'alert-circle'],
  ]);

  constructor(
    @Inject(Injector) private readonly injector: Injector,
    toastPackage: ToastPackage,
  ) {
    super(injector.get(ToastrService), toastPackage);
    this.setTypeStyle(this.toastPackage.toastType);
  }

  private setTypeStyle(type: string): void {
    const typeAvailable = this.typesStyles.has(type);
    if (typeAvailable) {
      this.iconName = this.typesStyles.get(type)!;
    }
  }
}
