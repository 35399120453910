import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';

import { TranslatePipe } from './pipes/translate.pipe';
import { CallPipe } from './pipes/call.pipe';
import { IconLabelComponent } from './icon-label/icon-label.component';
import { IconComponent } from './icon/icon.component';
import { NavBarComponent } from './navbar/navbar.component';
import { LayoutComponent } from './layout.component';
import { UserButtonComponent } from './user-button/user-button.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { ButtonComponent } from './button/button.component';
import { MainContentComponent } from './main-content/main-content.component';
import { TileLinkComponent } from './tile-link/tile-link.component';
import { PersonNameComponent } from './person-name/person-name.component';
import { AccordionComponent } from './accordion/accordion.component';
import {
  AccordionItemComponent,
  AccordionItemBodyDirective,
  AccordionItemHeaderDirective,
} from './accordion/accordion-item.component';
import { UserIdentityComponent } from './user-button/user-identity/user-identity.component';
import { UnavailabilityNoticeComponent } from './unavailability-notice/unavailability-notice.component';
import { MaintenancePageComponent } from './maintenance-page/maintenance-page.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    FeatherModule.pick(allIcons),
    MatMenuModule,
    MatIconModule,
    MatListModule,
    CallPipe,
    TranslatePipe,
    IconLabelComponent,
    IconComponent,
    NavBarComponent,
    LayoutComponent,
    UserButtonComponent,
    UserIdentityComponent,
    PageTitleComponent,
    ButtonComponent,
    MainContentComponent,
    TileLinkComponent,
    PersonNameComponent,
    AccordionComponent,
    AccordionItemComponent,
    AccordionItemBodyDirective,
    AccordionItemHeaderDirective,
    UnavailabilityNoticeComponent,
    MaintenancePageComponent,
  ],
  exports: [
    NavBarComponent,
    LayoutComponent,
    PageTitleComponent,
    FeatherModule,
    ButtonComponent,
    MainContentComponent,
    TileLinkComponent,
    TranslatePipe,
    PersonNameComponent,
    CallPipe,
    AccordionComponent,
    AccordionItemComponent,
    AccordionItemBodyDirective,
    AccordionItemHeaderDirective,
    MaintenancePageComponent,
  ],
})
export class LayoutModule {}
