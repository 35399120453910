import { Component, OnInit, inject } from '@angular/core';
import { Router, RouterLinkActive, RouterLink, RouterOutlet } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { NgClass, AsyncPipe } from '@angular/common';
import { MatMenuTrigger, MatMenu, MatMenuContent, MatMenuItem } from '@angular/material/menu';
import { FeatherModule } from 'angular-feather';
import { MatIcon } from '@angular/material/icon';
import { TranslatePipe } from '@ngx-translate/core';

import { AuthenticationService } from '../auth/authentication.service';
import { AuthPolicyService } from '@v2/core/services/authpolicy.service';
import { WellKnownPolicies } from '@v2/core/security/wellknownpolicies';
import { BrainnetPrincipal } from '@v2/core/security/models/brainnetprincipal';
import { RegexService } from '@v2/core/services/regex.service';
import { UnavailabilityNoticeService } from './unavailability-notice/unavailability-notice.service';
import { UserButtonComponent } from './user-button/user-button.component';
import { DateLabelComponent } from '../../v2/shared/components/date-label/date-label.component';
import { UnavailabilityNoticeComponent } from './unavailability-notice/unavailability-notice.component';
import { MaintenancePageComponent } from './maintenance-page/maintenance-page.component';
import { CallPipe } from './pipes/call.pipe';

const NAVIGATION_CONFIG: NavigationItem[] = [
  nav('navigation.home', '/supplier/home', []),
  nav('navigation.jobrequests', '/supplier/jobrequests', [WellKnownPolicies.lookAtJobrequests]),
  nav('navigation.assignments', '/supplier/assignments', [WellKnownPolicies.lookAtAssignments]),
  nav(
    'navigation.overviews',
    null,
    [],
    [
      nav('navigation.overviews-candidates', '/supplier/overviews/secondees', [WellKnownPolicies.lookAtJobrequests]),
      nav('navigation.contracts', '/supplier/overviews/contracts', [WellKnownPolicies.lookAtContracts]),
      nav('navigation.overviews-invoices', '/supplier/overviews/invoices', [WellKnownPolicies.lookAtFinancials]),
      nav('navigation.overviews-paymentproposals', '/supplier/overviews/payment-proposals', [
        WellKnownPolicies.approvePaymentProposals,
      ]),
      nav('navigation.overviews-hours-and-expenses', '/supplier/overviews/hours-and-expenses', [
        WellKnownPolicies.lookAtAssignments,
      ]),
    ],
  ),
];

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  imports: [
    NgClass,
    UserButtonComponent,
    RouterLinkActive,
    RouterLink,
    MatMenuTrigger,
    FeatherModule,
    MatIcon,
    DateLabelComponent,
    UnavailabilityNoticeComponent,
    RouterOutlet,
    MatMenu,
    MatMenuContent,
    MatMenuItem,
    MaintenancePageComponent,
    AsyncPipe,
    TranslatePipe,
    CallPipe,
  ],
})
export class LayoutComponent implements OnInit {
  drawerOpen: 'initial' | boolean = true;
  navigationConfig$?: Observable<NavigationItem[]>;
  unavailabilityNotice = inject(UnavailabilityNoticeService).unavailabilityNoticeState;

  readonly auth = inject(AuthenticationService);
  private readonly policyService = inject(AuthPolicyService);
  private readonly regexService = inject(RegexService);
  private readonly router = inject(Router);

  init = (): Observable<BrainnetPrincipal> => {
    return this.auth.principal$.pipe(switchMap((principal) => this.regexService.init().then(() => principal)));
  };

  ngOnInit(): void {
    const { policyService } = this;
    this.navigationConfig$ = this.auth.principal$.pipe(map((principal) => filter(principal, NAVIGATION_CONFIG)));

    this.router.events.subscribe(() => {
      this.drawerOpen = false;
    });

    function filter(principal: BrainnetPrincipal, items?: NavigationItem[]): NavigationItem[] {
      if (!(items instanceof Array)) return [];

      const filtered: NavigationItem[] = [];
      for (const item of items) {
        if (isAuthorized(principal, item)) {
          filtered.push({
            ...item,
            children: filter(principal, item.children),
          });
        }
      }
      return filtered;
    }

    function isAuthorized(principal: BrainnetPrincipal, item: NavigationItem): boolean {
      const stack = [item];

      while (stack.length) {
        const curr = stack.pop();

        if (!curr) {
          continue;
        }

        const { policies, children } = curr;
        if (policies.length === 0 && !children?.length) {
          // show when has no policies and has no children, e.g. 'Home'
          return true;
        }

        for (const policy of policies) {
          const authResult = policyService.authorize(policy);
          if (authResult.success) {
            return true;
          }
        }

        if (children) {
          stack.push(...children);
        }
      }

      return false;
    }
  }

  hideDrawer(): void {
    this.drawerOpen = false;
  }
  toggleDrawer(): void {
    this.drawerOpen = !this.drawerOpen;
  }
}

interface NavigationItem {
  label: string;
  segment: string | null;
  policies: string[];
  children?: NavigationItem[];
}

function nav(label: string, segment: string | null, policies: string[], children?: NavigationItem[]): NavigationItem {
  return {
    label,
    segment,
    policies,
    children,
  };
}
