import { Component, OnDestroy, OnInit } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { ReplaySubject } from 'rxjs';
import { NgScrollbar } from 'ngx-scrollbar';
import { TranslatePipe } from '@ngx-translate/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { EventService } from '@v2/core/services/event.service';
import { PrivacyStatement } from '@v2/data/schema/generated/PrivacyStatement';
import { PrivacyStatementHttpService } from '@v2/data/service/privacy-statement.http.service';
import { GlobalConstants } from '@v2/configs/global.constants';

@Component({
  selector: 'app-privacy-statement',
  templateUrl: './privacy-statement.component.html',
  styleUrls: ['./privacy-statement.component.scss'],
  imports: [NgScrollbar, TranslatePipe, PdfViewerModule],
})
export class PrivacyStatementComponent implements OnInit, OnDestroy {
  displayStatementUrl: string | null = null;

  private language = 'nl';
  private statement: PrivacyStatement | null = null;

  private readonly destroyed$ = new ReplaySubject(1);

  constructor(
    private msalService: MsalService,
    private avgService: PrivacyStatementHttpService,
    private eventService: EventService,
  ) {}

  ngOnInit(): void {
    this.language = localStorage.getItem(GlobalConstants.languageLocalStorageItemKey)!;
    this.setStatement();
  }

  accept(): void {
    this.avgService
      .accept()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        location.href = '/supplier/home';
      });
  }

  reject(): void {
    this.avgService
      .reject()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.msalService.logout();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private setStatement(): void {
    const privacyStatement = this.eventService.privacyStatement.value;
    if (privacyStatement != null) {
      this.statement = privacyStatement.value.privacyStatement;
      this.setDisplayStatementUrl();
    } else {
      this.avgService
        .check()
        .pipe(take(1))
        .subscribe((result) => {
          this.statement = result?.privacyStatement;
          this.setDisplayStatementUrl();
        });
    }
  }

  private setDisplayStatementUrl(): void {
    this.displayStatementUrl = this.language == 'nl' ? this.statement!.dutch : this.statement!.english;
  }
}
