import { Component, Input } from '@angular/core';
import { FeatherModule } from 'angular-feather';

@Component({
  selector: 'app-unavailability-notice',
  templateUrl: './unavailability-notice.component.html',
  styleUrls: ['./unavailability-notice.component.scss'],
  imports: [FeatherModule],
})
export class UnavailabilityNoticeComponent {
  @Input({ required: true }) message!: string;
}
