import { Component, Input, OnInit } from '@angular/core';
import { FeatherModule } from 'angular-feather';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  imports: [FeatherModule],
})
export class IconComponent implements OnInit {
  @Input() name = '';
  @Input() iconClass = '';

  customIcons: any[] = [
    'euro-sign',
    'euro',
    'euro-blue',
    'euro-blue-light',
    'payment-proposal',
    'payment-proposal-light',
    'user-money',
    'users-money',
  ];
  isCustom = false;

  ngOnInit(): void {
    this.isCustom = this.customIcons.indexOf(this.name) > -1;
  }
}
