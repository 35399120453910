import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { environment } from '@env';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  imports: [
    RouterModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    TranslatePipe,
  ],
})
export class ForgotPasswordComponent {
  emailForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });
  isSendingRequest = false;
  isEmailSent = false;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly snackBar: MatSnackBar,
    private readonly translate: TranslateService,
  ) {
    translate.addLangs(['en', 'nl']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang!.match(/en|nl/) ? browserLang! : 'nl');
  }

  submit(): void {
    if (this.emailForm.valid) {
      this.isSendingRequest = true;

      const email = this.emailForm.value.email!;

      const url = `${environment.v3.apiBaseUrl}/v1/identity/forgot/${encodeURIComponent(email)}`;

      this.httpClient.post(url, {}).subscribe({
        next: () => {
          this.isSendingRequest = false;
          this.isEmailSent = true;
        },
        error: () => {
          this.isSendingRequest = false;
          this.snackBar.open('Failed to send email. Please try again.', 'Close', {
            duration: 5000,
          });
        },
      });
    }
  }
}
